@media only screen and (min-width: 1260px) and (max-width: 1880px) {
    .mHeader h1 {
        font-size: 28px;
    }

    .mHeader h2 {
        font-size: 24px;
    }

    .mHeader h3 {
        font-size: 20px;
    }

    .mHeader {
        position: relative;
        height: 140px;
    }

    .mHeader .mandala-image {
        height: 140px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1260px) {
    .mHeader h1 {
        font-size: 25px;
    }

    .mHeader h2 {
        font-size: 21px;
    }

    .mHeader h3 {
        font-size: 18px;
    }

    .mHeader {
        position: relative;
        height: 140px;
    }

    .mHeader .mandala-image {
        height: 140px;
    }

    .table thead {
        font-size: 15px;
    }

    /*-------SignIn--------------*/
    .signin form {
        width: 40%;
    }

    .Registration_form form {
        width: 70%;
    }

    .forget_password_form {
        width: 50%;
    }

    /*-------Footer--------------*/
    .footerLeft {
        padding-left: 25px !important;
    }

    .footerMiddle {
        padding-right: 25px !important;
    }

    .footerRight {
        padding: 0 25px !important;
    }

    .footerLeft h3 {
        font-size: 14px !important;
    }

    .footerMiddle h3 {
        font-size: 13px !important;
    }

    .footerRight h3,
    h4 {
        font-size: 13px !important;
    }

    .footerBottom h3 {
        font-size: 13px !important;
        margin: 0 5px;
    }

    /*--------document submission form------*/
    .document_submission_main .upload_btn button {
        margin-left: -15px;
        margin-top: 22px;
    }

    .document_submission_main .doc_image {
        height: 130px;
    }

    .table_row {
        width: 22.66%;
    }

    .family_member_table {
        width: 12.33%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {

    /*----application form nav-----*/
    .application_process_nav_list li {
        margin: 0px 10px;
    }

    .application_process_nav h2 {
        font-size: 11px !important;
    }

    .back_office_nav_list li {
        margin: 0px 15px;
    }

    .back_office_nav_list li h2 {
        font-size: 14px !important;
    }

    .hometab {
        margin: 0px 20px !important;
    }

    .signInButton {
        padding: 4px 4px !important;
    }

    .signInButton h3 {
        font-size: 17px !important;
    }

    .font-resizer {
        padding: 3px 4px !important;
        margin: 0px 10px;
    }

    .font-resizer h3,
    .website-theme h3 {
        font-size: 17px !important;
    }

    .website-theme {
        padding: 3px 6px !important;
    }
    .Front_Office_Btn .dashboard_delete_button button {
        font-size: 13px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1327px) {

    /*----application form nav-----*/
    .application_process_nav h2 {
        font-size: 15px !important;
    }

}


@media only screen and (min-width: 1024px) and (max-width: 1320px) {
    .home-screen-nav-desktop h2 {
        font-size: 15px !important;
    }

    .application_process_nav_list li h2 {
        font-size: 12.95px !important;
    }

    .application_form_nav_list li h2 {
        font-size: 13px !important;
    }

    .mHeader h1 {
        font-size: 21px !important;
    }

    /*-----homescreen carousel----*/
    .main_carousel_text h1 {
        width: auto;
    }

    .main_carousel_text h2 {
        width: 33%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-size: 60% !important;
    }

    /*-----homescreen carousel----*/
    .main_carousel_text {
        top: 10%;
        left: 75px;
    }

    .main_carousel_text h1 {
        font-size: 20px !important;
    }

    .main_carousel_text h2 {
        width: 30%;
        font-size: 20px !important;
    }

    .main_carousel_text h3 {
        font-size: 15px !important;
    }

    .homescreen_notification_card {
        width: 80%;
        height: 400px;
        margin: auto;
        margin-bottom: 15px;
    }

    /*------------*/
    .font-resizer {
        display: none !important;
    }

    .website-theme {
        /* display: none !important; */
    }

    /* .home-screen-nav-desktop {
        display: none !important;
    } */

    .application_process_nav {
        background-color: transparent;
        display: block !important;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .application_process_nav h2 {
        font-size: 11px !important;
        font-weight: 800;
    }

    .application_process_nav ul {
        width: 95%;
        margin: auto;
        margin-top: 5px;
        box-shadow: 0px 3px 5px 0px #d5d6d8;
        border-radius: 5px;
    }

    .application_process_nav li {
        text-align: center;
        /* width: 35%; */
        margin: auto;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mHeader {
        height: auto;
    }

    .mandala-image {
        display: none !important;
        visibility: hidden !important;
    }

    .main-header {
        position: relative !important;
        top: 0px !important;
    }

    .main-header h1,
    h2,
    h3 {
        font-size: 100% !important;
    }

    .mobile_menu_main {
        display: block;
        top: 4.4%;
    }

    /*-------SignIn--------------*/
    .signin form {
        width: 60%;
    }

    .signin form h1 {
        font-size: 24px !important;
    }

    .signin form .input_field_div1 .fa,
    .signin form .input_field_div2 .fa {
        top: 39px;
    }

    .Registration_form form {
        width: 70%;
    }

    .Registration_form form h3 {
        color: #707070 !important;
    }

    .forget_password_form {
        width: 70%;
    }

    .my_application_main table {
        width: 95%;
    }

    .myapplication_card_data p {
        font-size: 14px !important;
    }

    .notification_time_date_main {
        justify-content: space-between;
        padding: 0px 10px;
    }

    /*----mobile image gallery----*/
    .desktop_gallery {
        display: none;
    }

    .mobile_img_gallery {
        display: block;
        margin-top: 20px;
    }

    .mobile_img_gallery .carousel-indicators {
        display: none;
    }

    .grievance_modal form {
        width: 60%;
    }

    .uploaded_image {
        margin-top: 10px;
    }

    .downloads_tab_info,
    .application_flow_main {
        width: 500px;
        height: auto;
        top: 30px;
        left: -100%;
        padding: 20px;
    }

    .application_flow_main {
        left: -160%;
    }

    /*--------css for footer section-----*/
    .footerLeft {
        padding-left: 0px !important;
    }

    .footerMiddle {
        padding-right: 0px !important;
    }

    .footerRight {
        padding: 0 0px !important;
    }

    .footerLeft h3 {
        font-size: 14px !important;
    }

    .footerMiddle h3 {
        font-size: 13px !important;
    }

    .footerRight h3,
    h4 {
        font-size: 13px !important;
    }

    .name_input_box {
        padding-right: 5px !important;
    }

    .email_input_box {
        padding-left: 5px !important;
    }

    .remittance_acknowledge_dialogue_box {
        width: 60%;
    }

    /*---------Education expenses detail form-----*/
    .total_expenses span {
        left: 6px;
    }

    /*-------document submission-----*/
    .other_documents .doc_image {
        width: 172px;
        height: 160px;
        margin-left: 58px;
    }

    .back_office_button button,
    .back_office_button label,
    .upload_home_verification_section label,
    .upload_home_verificaion_button button {
        font-size: 14px !important;
    }

    .back_office_preview .user_msg_main,
    .back_office_user_msg_main,
    .back_office_user_message_main,
    .user_message_main {
        width: 70% !important;
        margin: 0px 20px;
    }

    .user_chatbot_textarea_main {
        padding: 0px 20px;
    }

    .user_milestone {
        width: 100%;
    }

    .profile_tab {
        /* right: 10px; */
        /* top: -45px; */
        left: 93%;
    }

    .logout_box_front {
        margin-top: 17px;
        width: 37%;
        height: 21vh;
        top: 30px;
        right: 38px;
    }

    .profile_tab i {
        font-size: 35px !important;
    }

    /* .logout_box_front span {
        left: 88%;
    } */

    .logout_box_front .button {
        padding: 1px 20px;
        border-radius: 50px;
        font-size: 16px;
        left: 10px;
        font-weight: 600;
        position: absolute;
    }

    .uploaded_documents h4 {
        font-size: 13px !important;
    }

    .back_office_user_textarea_main,
    .user_chatbot_textarea_main {
        width: 90%;
    }

    .close_back_office_preview,
    .close_user_chatbot {
        top: 2px;
    }

    .logout_box_admin {
        width: 25% !important;
        top: 11%;
        right: 3% !important;
    }

    .sidebar_parent {
        font-size: 14px;
    }

    .create_form {
        width: 80%;
    }

    .user_grievance_chatbot_comment_section {
        padding: 0px 0px;
    }

    .user_chatbot_textarea_main {
        width: 100%;
        padding: 0px 10px;
    }

    .grievance_button {
        width: 45%;
    }

    .Front_Office_Btn .dashboard_delete_button button {
        font-size: 13px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-size: 60% !important;
    }

    /*------homescreen carousel-----*/
    .main_carousel_text {
        top: 14% !important;
        left: 30px;
    }

    .main_carousel_text h1 {
        width: auto;
        font-size: 15px !important;
    }

    .main_carousel_text h2 {
        width: 60%;
        font-size: 15px !important;
    }

    .main_carousel_text h3 {
        margin-top: 10px !important;
        width: 80%;
        font-size: 12px !important;
        letter-spacing: 0;
        line-height: 16px;
    }

    .homescreen_notification_card_main {
        padding: 20px 0px 0px 0px;
    }

    .homescreen_notification_card {
        width: 100% !important;
        height: 400px;
        margin-bottom: 15px;
    }

    .table thead {
        font-size: 15px;
    }

    .footer {
        padding: 10px;
    }

    .footer h3,
    .footer h4 {
        font-size: 14px !important;
    }

    .footerLeft h3 {
        font-size: 14px !important;
    }

    .footerBottom h3 {
        font-size: 14px !important;
    }

    .footer_ownership h4 {
        font-size: 13px !important;
    }

    .searchNav {
        width: 100% !important;
        text-align: center;
    }

    .searchNav input {
        width: 90% !important;
    }

    .bNav {
        justify-content: space-around !important;
    }

    .font-resizer {
        display: none !important;
        visibility: hidden !important;
    }

    .website-theme {
        display: none !important;
        visibility: hidden !important;
    }

    .carousel_main_section {
        height: 300px !important;
    }

    .carousel_main_section img {
        height: 300px !important;
    }

    .application_process_nav {
        background-color: transparent;
        display: block !important;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .application_process_nav ul {
        width: 95%;
        margin: auto;
        margin-top: 5px;
        box-shadow: 0px 3px 5px 0px #d5d6d8;
        border-radius: 5px;
    }

    .application_process_nav li {
        text-align: center;
        width: 80%;
        margin: auto;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mobile_menu_main {
        display: block;
        top: 88px;
    }

    .mHeader {
        height: auto;
    }

    .mandala-image {
        display: none !important;
        visibility: hidden !important;
    }

    .main-header {
        position: relative !important;
        top: 0px !important;
    }

    .main-header-images {
        height: 50px;
    }

    .search-input-div {
        margin-left: 2px !important;
    }

    .home-screen-nav-desktop {
        display: none !important;
    }

    .main-carousel-text {
        left: 60px !important;
        top: 20% !important;
    }

    .application_process_nav h2 {
        font-size: 15px !important;
        font-weight: 800;
    }

    /*-------SignIn ,Registration, Forget password--------------*/
    .signin {
        padding: 5% 0;
    }

    .signin form,
    .forget_password_form,
    .Registration_form form {
        width: 90%;
        border-radius: 25px !important;
    }

    .signin form h1 {
        font-size: 18px !important;
    }

    .signin .input_field1,
    .signin .input_field2,
    .forget_password .input_field1,
    .forget_password .input_field2,
    .select_committee {
        width: 90%;
    }

    .signin .button {
        font-size: 15px !important;
    }

    .newreg_or_forgot button,
    .forget_password button {
        font-size: 13px;
    }

    .signin h3,
    .forget_password h3 {
        font-size: 14px !important;
    }

    .signin form .forget_password_form,
    .Registration_form form {
        padding: 5%;
    }

    .signin form .input_field_div1 .fa,
    .signin form .input_field_div2 .fa {
        left: 9%;
        top: 37px;
    }

    /*-----Forget Password, Registration, Forget password----*/
    .eligible_criteria_info_mobile li,
    .application_guideline_info_mobile li,
    .downloads_tab_info_mobile li {
        font-size: 14px !important;
        line-height: 30px !important;
    }

    .downloads_tab_info,
    .application_flow_main {
        width: 300px;
        height: auto;
        top: 30px;
        left: -46%;
        padding: 5px;
    }

    .application_flow_main {
        left: -121%;
    }

    /*----------latest notification-------------*/
    .latest_notification_main {
        width: 95%;
    }

    .card_right_col {
        justify-content: start !important;
    }

    .notification_time_date_main {
        justify-content: space-between;
        padding: 0px 10px;
    }

    .notification_time_date_main .apply_btn button {
        padding: 2px 15px;
    }

    /*----mobile image gallery----*/
    .desktop_gallery {
        display: none;
    }

    .mobile_img_gallery {
        height: auto;
        display: block;
        margin-top: 20px;
    }

    .mobile_img_gallery img {
        width: 300px;
        height: 300px !important;
    }

    .mobile_img_gallery .carousel-indicators {
        display: none;
    }

    .milestone_main {
        height: 270px !important;
    }

    .milestone_div {
        height: 270px !important;
    }

    .milestone_status_completed::before,
    .milestone_status_inprogress::before,
    .milestone_status_new_triggered::before,
    .milestone_status_rejected::before {
        width: 10px;
        height: 10px;
    }

    .milestone_status_completed,
    .milestone_status_inprogress,
    .milestone_status_new_triggered,
    .milestone_status_rejected {
        margin: 0px 6px;
    }

    .milestone_status_completed h4,
    .milestone_status_inprogress h4,
    .milestone_status_new_triggered h4,
    .milestone_status_rejected h4 {
        margin-left: 6px;
        font-size: 13px !important;
    }

    .remittance_acknowledge_dialogue_box {
        width: 95%;
        top: 50%;
    }

    .grievance_main,
    .myapplication_main {
        padding: 10px 0px;
    }

    .grievance_card_heading,
    .grievance_card_data,
    .myapplication_card_heading,
    .myapplication_card_data,
    .docsubmission_card_heading,
    .docsubmission_card_data,
    .AlertNotification_card_heading,
    .AlertNotification_card_data {
        width: 95%;
    }

    .grievance_card_data span,
    .myapplication_card_data span,
    .docsubmission_card_data span,
    .AlertNotification_card_data span,
    .Remitance_card_data span {
        font-size: 12px;
    }

    .AlertNotification_card_title {
        padding-left: 10px !important;
    }

    .grievance_btn {
        width: 95%;
    }

    .grievance_form_btn {
        width: 100%;
    }

    .grievance_btn button {
        font-size: 12px;
        padding: 2px 5px;
    }

    .grievance_modal form {
        width: 95%;
    }

    .upload_document_detail_main {
        width: 95%;
    }

    .upload_document_detail_main span {
        font-size: 12px;
    }

    .upload_btn button {
        font-size: 14px;
    }

    .uploaded_image {
        margin-top: 10px;
    }

    .choose_file input {
        font-size: 12px;
    }

    .Remitance_card_heading {
        width: 100%;
    }

    .Remitance_card_data {
        width: 100%;
    }

    .Remitance_card_title h3 {
        padding-left: 5px !important;
    }

    .Remitance_card_data_main {
        flex-direction: column;
    }

    .aknowledge_btn {
        margin: 0px 0px 20px 0px;
    }

    .aknowledge_btn button {
        padding: 2px;
        font-weight: 600;
        font-size: 12px;
    }

    /*--------css for footer section-----*/
    .footerLeft {
        padding-left: 0px !important;
    }

    .footerMiddle {
        padding-right: 0px !important;
        margin: 10px 0px !important;
    }

    .footerRight {
        padding: 0 0px !important;
    }

    .footerLeft h3 {
        font-size: 13px !important;
    }

    .footerMiddle h3 {
        font-size: 14px !important;
    }

    .footerRight h3,
    h4 {
        font-size: 13px !important;
    }

    .name_input_box {
        padding-right: 5px !important;
    }

    .email_input_box {
        padding-left: 5px !important;
    }

    .footerBottom {
        margin-top: 10px !important;
    }

    /******Personal Details form***************/
    .form_button {
        margin-top: 20px;
        justify-content: end;
    }

    .form_button .form_save-btn {
        margin: 0px 10px;
        padding: 5px 10px;
        font-size: 14px !important;
    }

    .form_button .form_cancel_btn {
        padding: 5px 10px;
        font-size: 14px;
    }

    .form_button .form_submit_btn {
        font-size: 14px;
    }

    /*------family income details form------*/
    .family_income_details_main {
        margin: 10px 0px;
    }

    /*------education expenses details form-----*/
    .education_expenses_detail_form form {
        margin-top: 15px;
    }

    .education_expenses_label {
        display: block;
        margin-top: 15px;
    }

    .input_field_label {
        display: block;
    }

    /*-----document detail form------*/
    .other_documents {
        margin-top: 20px;
    }

    .other_documents .select_file {
        margin-top: 10px;
    }

    .doc_image {
        margin-top: 10px;
    }

    .other_documents .doc_image {
        width: 200px;
        height: 160px;
        margin-left: 0px;
        margin-top: 10px;
    }

    .back_office_button button,
    .back_office_button label,
    .upload_home_verification_section label,
    .upload_home_verificaion_button button {
        font-size: 10px !important;
        padding: 3px 6px;
    }

    .comment_to_write {
        width: 95%;
    }

    .comment_to_show {
        width: 90%;
    }

    .comment_to_write button {
        padding: 2px 6px;
        font-size: 12px;
    }

    .back_office_preview .user_msg_main,
    .back_office_user_msg_main,
    .back_office_user_message_main,
    .user_message_main {
        width: 90%;
    }

    .back_office_preview,
    .user_grievance_chatbot_comment_main {
        padding: 10px 5px;
    }

    .back_office_user_textarea_main,
    .user_chatbot_textarea_main {
        width: 100%;
        padding: 0px;
    }

    .user_grievance_chatbot_comment_section {
        padding: 0px 5px;
    }

    .user_chatbot_textarea {
        flex-direction: column;
        width: 95%;
        margin: auto;
    }

    .user_chatbot_text_file {
        width: 100%;
    }

    .grievance_button {
        width: 100% !important;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .back_office_user_textarea button,
    .user_chatbot_textarea button {
        margin-left: 5px;
    }

    .back_office_notification {
        padding: 30px 10px;
    }

    .user_milestone {
        width: 100%;
    }

    .multiple_documents_upload {
        margin: 10px 0px;
    }

    .profile_tab {
        right: 10px;
        top: -45px;
        left: 88%;
    }

    .logout_box_front {
        margin-top: 17px;
        width: 70%;
        height: 22vh;
        top: -20px;
        right: 20px;
    }

    .logout_box_front span {
        left: 87%;
    }

    .logout_box_front .button {
        padding: 1px 20px;
        border-radius: 50px;
        font-size: 16px;
        left: 10px;
        font-weight: 600;
        position: absolute;
    }

    .amount_disbursed {
        min-height: 240px;
    }

    .tabel_heading {
        width: 750px;
        margin-left: 10px;
    }

    .tabel_data {
        width: 750px;
        margin-left: 10px;
    }

    .close_back_office_preview,
    .close_user_chatbot {
        top: 1px;
    }

    .downloadinfo_card_apply {
        padding: 10px 0px;
    }

    .downloadinfo_card_apply ul {
        padding: 0;
    }

    .download_user_button {
        margin-left: 0px;
        width: 80px;
    }

    .user_refund .dashboard_view_data_heading {
        width: 750px;
    }

    .user_refund .dashboard_view_data {
        width: 750px;
    }
}

@media only screen and (max-width: 320px) {
    .mobile_menu_main {
        display: block;
        top: 84px;
    }

    .mobile_menu_main .hamburger {
        padding: 40px 0px 0px 20px;
    }

    /*-------decument submission form----*/
    .document_submission_main .upload_btn {
        margin-top: 10px;
    }

    .document_submission_main .doc_image {
        margin-top: 10px;
    }

    .other_documents .doc_image {
        width: 160px;
    }

    .signin form .input_field_div1 .fa,
    .signin form .input_field_div2 .fa {
        left: 10%;
    }
}

@media only screen and (max-width: 1024px) {
    .back_office_nav_list li h2 {
        font-size: 11px !important;
    }

    .application_process_nav_list li h2 {
        font-size: 12px !important;
    }

    .milestone_section {
        width: 90%;
    }

    .milestone_div {
        width: 85%;
    }

    .logout_box_front {
        width: 25%;
    }

    .profile_tab i {
        font-size: 28px;
    }

    .logout_box_front {
        top: 34px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
    .main-header-images {
        height: 35px;
    }

    .milestone_status_completed h4,
    .milestone_status_inprogress h4,
    .milestone_status_new_triggered h4,
    .milestone_status_rejected h4 {
        font-size: 9px !important;
    }
}

@media only screen and (min-width: 410px) and (max-width: 767px) {

    .downloads_tab_info,
    .application_flow_main {
        width: 390px;
        height: auto;
        top: 30px;
        left: -100%;
        padding: 10px;
    }

    .application_flow_main {
        left: -160%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 768px){
    .Admin_profile_tab{
        position: absolute;
        top: 0%;
        right: 5%;
    }

    .Admin_profile_tab i {
        font-size: 25px;
    }

    input ::placeholder{
        font-size: 5px;
    }
    .font-resizer{
        display: none;
    }

    .home_screen_nav h2 {
        color: #0d2750;
        font-size: 11px!important;
        font-weight: 600;
    }
    .hometab {
        margin: 0px 20px !important;
    }
   
}


@media only screen and (min-width: 768px) and (max-width:1024px){
    .Admin_profile_tab {
        position: absolute;
        top: 1%;
        right: 0.5%;
    }
    .Admin_profile_tab i {
        font-size: 27px;
    }

    .logout_box_admin {
        height: 24vh;
    }
    .remittance_preview, .student_marks_detail {
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 #ccc;
        margin: auto;
        width: 100%;
    }
    .mom_box {
        position: absolute;
        top: 39%;
        left: 70%;
        width: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    .Front_Office_Btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        gap: 15px;
    }
}

@media only screen and (max-width:1024px) {
.back_office_nav_list li {
    margin: 0 10px;
    }

    .back_office_nav_list li h2 {
        font-size: 11px ;
    }
    
    .application_process_nav li{
        margin: 0 10px;
    }
    .application_process_nav li H5{
font-size: 11px !important;
    }
    .application_process_nav{
        font-size: 11px;
    }
    .dashboard_back_office .dashboard_view_data_main h4 {
        font-size: 11px !important;
        font-weight: bold;
    }
H5{
    font-size: 11px;
}


}


