body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

/* width of the entire scrollbar */
::-webkit-scrollbar {
  width: 3px !important;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, rgb(175, 175, 175)) !important;
  border-radius: 15px;
}

/*-----Hide hide/show password icon for microsoft edge browser---*/

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/*---Hide input type = number default spin buttons */

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  /* width: 0;
  height: 0; */
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 16px !important;
}

select {
  font-size: 16px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

h4 {
  padding: 3px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.goog-te-gadget {
  height: 35px;
  overflow: hidden;
}

.goog-te-gadget .goog-te-combo {
  width: 150px;
  height: 30px;
  border-radius: 5px;
}

/* .goog-te-banner-frame {
  display: none;
  visibility: hidden;
} */

.mHeader {
  position: relative;
  height: 170px;
}

.mHeader .mandala-image {
  height: 170px;
}

.home-screen-nav-desktop {
  border-top: none !important;
  padding: 10px 0 !important;
}

.home_screen_nav {
  justify-content: center;
  margin-top: 5px;
  height: 50px;
}

.home_screen_nav h2 {
  /* margin: 0px 10px; */
  font-weight: 600;
  color: #0d2750;
  font-size: 18px !important;
  /* margin: auto 25px; */
}

.home_screen_nav h2:hover {
  color: #6d8dad !important;
}

.hometab {
  margin: 0px 30px !important;
}

.action_header_home_icon {
  display: flex;
  align-items: center;
}

.action_header_home_icon h3 {
  padding: 5px 7px 5px 7px;
  background-color: #fff;
  border-radius: 6px;
}

.action_header_home_icon .fa {
  font-size: 22px;
  cursor: pointer;
}

.home_icon .fa {
  font-size: 22px;
  cursor: pointer;
}

.goog-te-banner {
  display: none;
}

/*-----Application process nav--------------*/

.application_process_nav {
  display: block !important;
  position: sticky;
  top: 49px;
  z-index: 111;
}

.application_process_nav .navbar-toggler {
  border: none;
}

.application_process_nav li {
  margin: 0px 15px;
  cursor: pointer;
}

.application_process_nav h2 {
  font-size: 16px !important;
  font-weight: bold !important;
}

.application_process_nav h2:hover {
  color: #6d8dad !important;
}

.application_process_nav_list li {
  margin: 0px 15px;
}

.application_process_nav_list li h2 {
  font-size: 16px !important;
}

.application_form_nav_list li h2 {
  font-size: 17px !important;
}

/* .application_process_nav .active {
  border-bottom: 5px solid #6d8dad;
  color: #6d8dad !important;
} */

/* .application_process_nav li a {
  color: #0D2750;
  font-size: 18px;
  font-weight: bold;
} */

.home-screen-nav-hover-underline {
  width: "100%";
  height: "10px";
  background-color: "#6D8DAD";
  border-radius: "5px 5px 0px 0px";
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #6d8dad;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
  transform: scaleX(0);
  height: 3px;
  /* background-color: #6d8dad; */
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline-animation-dark {
  display: inline-block;
  position: relative;
  color: #ffff00;
}

.hover-underline-animation-dark:after {
  content: "";
  position: absolute;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #ffff00;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}

.hover-underline-animation-dark:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.application_process_nav_list .badge,
.back_office_nav_list .badge {
  position: absolute;
  top: -6px;
  right: -15px;
  font-size: 9px;
  background-color: yellow;
  color: #000;
}

/*---------------css for homescreeen carousel----*/

.main_carousel_text {
  width: 100%;
  position: absolute;
  top: 20%;
  left: 110px;
}

.main_carousel_text h1 {
  width: 50%;
  font-family: Roboto Condensed;
  color: #fff;
}

.main_carousel_text h2 {
  width: 25%;
  font-family: Roboto Condensed;
  color: #fff;
  border-bottom: 3px solid #fff;
}

.main_carousel_text h3 {
  width: 50%;
  font-family: Open Sans;
  color: #fff;
  letter-spacing: 1px;
  line-height: 23px;
  margin-top: 20px;
}

.carousel-control-prev,
.carousel-control-next {
  width: 8%;
}

.no-display-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input[type="text"] {
  font-family: "Open Sans";
}

textarea {
  font-family: "Open Sans";
}

.feedback_error {
  color: red;
  font-size: 16px;
}

/*--------css for tab info div-----*/

.carousel_main_section {
  position: relative;
}

/*---css for statistic----*/

.statistic_section,
.Hierarchy_ImageBox {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  background-color: rgba(29, 28, 28, 0.781);
  z-index: 111;
}

.statistic_img {
  width: 85%;
  height: 100vh;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.statistic_img img {
  width: 100%;
  height: 100%;
}

.statistic_section span,
.Hierarchy_ImageBox span {
  position: absolute;
  top: 5px;
  right: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.application_flow_public_portal {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  position: relative;
}

.application_flow_public_portal img {
  width: 100%;
  height: 100%;
}

.application_flow_public_portal span {
  position: absolute;
  top: 10px;
  right: 10px;
}

.application_flow_public_portal span .fa {
  font-size: 30px;
  cursor: pointer;
}

.Hierarchy_ImageBox span {
  top: 30px;
}

.Hierarchy_ImageBox {
  height: 100%;
}

.Hierarchy_ImageBox img {
  width: 100%;
  height: 100%;
}

.Hierarchy_ImageBox .image_box {
  margin-top: 10%;
}

.statistic_main {
  width: 1200px !important;
  margin-top: -3px;
}

.statistic_main img {
  width: 165px;
  height: 200px;
}

/*-----css for eligible criteria-----*/

.eligible_criteria_info,
.application_guideline_info,
.downloads_tab_info,
.statistic_main,
.application_flow_main {
  width: 500px;
  position: absolute;
  top: 38px;
  left: 0%;
  background-color: #fff;
  padding: 1.4rem;
  border-radius: 12px;
  box-shadow: 0px 3px 6px 0px #dbdada;
  z-index: 11;
  margin-top: -3px;
}

.Department_hierarchy {
  position: absolute;
  top: 38px;
  left: 0%;
  background-color: #fff;
  /* padding: 1.4rem;
  border-radius: 12px;
  box-shadow: 0px 3px 6px 0px #dbdada; */
  z-index: 11;
  margin-top: -3px;
}

.Department_hierarchy ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Department_hierarchy li {
  padding: 5px;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
  margin-bottom: 5px;
  color: rgb(13, 39, 80);
}

.Department_hierarchy li:hover {
  color: white;
  background-color: #0d6efd;
}

.Hierarchy_Department_mobile_main {
  width: 100%;
  background-color: #fff;
}

.hierarchy_img img {
  width: 100%;
  height: 100%;
}

.Hierarchy_Department_mobile_main li {
  padding: 3px !important;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
  margin-bottom: 5px;
  color: rgb(13, 39, 80) !important;
  font-size: 14px !important;
}

.Hierarchy_Department_mobile_main ul {
  list-style: none;
  padding: 0 !important;
  margin: 0;
}

.Hierarchy_Department_mobile_main li:hover {
  color: white;
  background-color: #0d6efd;
}

.application_flow_main {
  left: -80%;
  overflow-y: scroll;
  height: 400px;
}

.application_flow_main img {
  width: 100%;
  height: 100%;
}

.Department_hierarchy img {
  width: 100%;
  height: 100%;
}

.eligible_criteria_info ul,
.application_guideline_info ul,
.downloads_tab_info ul {
  list-style: none;
  padding-left: 20px;
}

.eligible_criteria_info ul li,
.application_guideline_info ul li,
.downloads_tab_info ul li {
  text-align: justify;
  margin-bottom: 0.5rem;
  color: #707070;
}

.eligible_criteria_info ul li::before,
.application_guideline_info ul li::before,
.downloads_tab_info ul li::before {
  position: absolute;
  content: " ";
  width: 8px;
  height: 8px;
  background-color: #c4c4c4;
  border-radius: 50%;
  margin-left: -22px;
  margin-top: 8px;
}

.application_guideline_info {
  left: 0%;
  overflow-y: scroll;
  height: 400px;
}

.downloads_tab_info {
  left: 0%;
  overflow-y: scroll;
  height: 400px;
}

/*------css for mobile tab info div start----*/

.eligible_criteria_info_mobile,
.application_guideline_info_mobile,
.downloads_tab_info_mobile,
.statistic_mobile_main,
.application_flow_mobile_main,
.Hierarchy_Department_mobile_main {
  width: 100%;
  height: 350px;
  background-color: #fff;
  padding: 1.4rem;
  border-radius: 12px;
  box-shadow: 0px 5px 10px 0px #0d2750;
  overflow-y: scroll;
}

.eligible_criteria_info {
  overflow-y: scroll;
  height: 400px;
}

.statistic_mobile_main {
  padding-left: 12px;
}

.application_flow_mobile_main img,
.Hierarchy_Department_mobile_main img {
  width: 100%;
  height: 100%;
}

.eligible_criteria_info_mobile ul,
.application_guideline_info_mobile ul,
.downloads_tab_info_mobile ul {
  padding: 0px 15px !important;
  list-style-type: disc !important;
}

.eligible_criteria_info_mobile li,
.application_guideline_info_mobile li,
.downloads_tab_info_mobile li {
  color: #707070 !important;
  line-height: 40px !important;
  text-align: left !important;
}

.mobile_tab_info_box {
  margin-top: -20px !important;
}

/*------css for mobile tab info div end----*/

/*-----css for homescreen notification card----*/

.homescreen_notification_card_main {
  padding: 35px 16px 16px 16px;
}

.homescreen_notification_card {
  height: 500px;
  width: 100%;
  overflow: hidden;
  border: 1px solid #c4c4c4;
  border-radius: 14px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.homescreen_notification_card_header {
  height: 50px;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 14px 14px 0px 0px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  justify-content: center;
}

.homescreen_notification_card_title {
  font-family: Open Sans;
  font-weight: bold;
  align-self: center;
  text-align: -webkit-center;
}

.homescreen_notification_card_body {
  padding: 20px;
  height: 450px;
  /* overflow-y: scroll !important; */
  overflow: scroll;
}

.homescreen_notification_card_body a {
  text-decoration: none;
}

.homescreen_notification_card_body h3 {
  font-size: 16px !important;
}

.table {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.table thead {
  background-color: #f0f0f3;
  position: sticky;
  top: 0;
}

.gallery_section h1 {
  font-size: 30px !important;
}

.desktop_gallery {
  margin-bottom: 30px !important;
}

/*-----css for Footer Col Start-----*/

.footer {
  padding: 20px;
}

.footerLeft {
  padding-left: 40px !important;
}

.footerMiddle {
  padding-right: 40px !important;
}

.footerRight {
  padding: 0 40px !important;
}

.footer h2 {
  font-size: 18px !important;
}

.footer h3,
.footer h4 {
  font-size: 16px !important;
}

.footerMiddle h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 23px;
}

.footerMiddle h4 {
  line-height: 23px;
}

.footer_ownership h4 {
  margin-bottom: 3px;
  font-size: 16px !important;
}

.social_media_links {
  display: flex;
}

.social_media_links span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0px 8px 0px 0px;
  background-color: #fff;
}

.social_media_links .fa {
  color: #000;
}

.social_media_links span:hover {
  background-color: #6d8dad;
  transition: 0.6s ease;
  cursor: pointer;
}

.social_media_links span:hover .fa {
  color: #fff;
}

/*-----css for Footer Col End-----*/

/*----css for SignIn page start-------------------*/

.signin,
.Registration_form,
.forget_password,
.reset_password_section {
  width: 100%;
  padding: 2% 0%;
}

.signin form,
.Registration_form form,
.forget_password_form,
.reset_password_form {
  width: 40%;
  margin: auto;
  background-color: #ececec;
  text-align: center;
  padding: 2%;
  border-radius: 50px;
  box-shadow: 0px 3px 10px 0px rgba(13, 39, 80, 0.2);
}

.signin form h1,
.Registration_form form h1,
.forget_password h1,
.reset_password_form h1 {
  margin-bottom: 40px;
  font-size: 22px !important;
  font-weight: 600;
}

.Registration_form input,
.Registration_form select {
  font-size: 16px !important;
}

.signin form .input_field_div1,
.signin form .input_field_div2 {
  position: relative;
}

.signin form .input_field_div1 .fa,
.signin form .input_field_div2 .fa {
  position: absolute;
  left: 18%;
  top: 42px;
  font-size: 1.2rem;
  color: #707070;
  opacity: 0.4;
  z-index: 1;
}

.signin .input_field1,
.signin .input_field2,
.forget_password .input_field1,
.forget_password .input_field2 {
  width: 70%;
  margin: auto;
  position: relative;
}

.signin .input_field1,
.signin .input_field2 {
  margin-top: 10px;
}

.signin .input_field1 input,
.input_field2 input,
.forget_password .input_field1 input,
.forget_password .input_field2 input,
.Registration_form select,
.reset_password_form input {
  border-radius: 50px;
  /* padding-left: 35px; */
}

.signin .input_field1 input,
.input_field2 input {
  padding-left: 35px;
}

.signin input:focus,
.Registration_form input:focus,
.Registration_form select:focus,
.forget_password input:focus,
.reset_password_form input:focus {
  border: 1px solid #707070;
  box-shadow: none;
}

.forget_password .input_field1 .fa {
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: #707070;
  opacity: 0.4;
}

.signin .input_field_div2 {
  margin: 25px 0px 40px 0px;
}

.signin label,
.Registration_form label,
.forget_password label {
  margin-bottom: 10px;
  font-size: 16px;
}

.Registration_form label,
.reset_password_form label {
  margin-bottom: 0px;
  font-size: 16px;
}

.password_field {
  position: relative;
}

.password_field span {
  position: absolute;
  top: 3px;
  right: -20px;
}

.password_field .showPass,
.reset_password_form .showPass {
  position: absolute;
  top: 6px;
  right: -14px;
}

.signin .button,
.Registration_form .button,
.forget_password .button,
.reset_password_form .button {
  padding: 6px 20px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  border: 1px solid #707070;
  color: #1e2343;
  background-color: #fff;
  position: relative;
}

.signin .button {
  padding: 5px 20px;
}

.signin .button h3 {
  font-weight: 600;
}

.signin .button:hover,
.Registration_form .button:hover,
.forget_password .button:hover,
.reset_password_form .button:hover {
  background-color: #6d8dad;
  color: #fff;
  transition: 0.3s ease;
  border: 1px solid #6d8dad;
}

.newreg_or_forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px 0px 30px 0px;
}

.forget_password .newreg_or_forgot {
  margin: 30px 0px 0px 0px;
}

.newreg_or_forgot button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: normal;
}

.newreg_or_forgot button:hover h4,
.newreg_or_forgot button:hover h3 {
  color: #0d2750 !important;
}

.invalid-feedback {
  color: red;
}

/*----css for SignIn page end-------------------*/

/*----css for registration page start-------------------*/

.Registration_form form {
  width: 40%;
}

.Registration_form form label,
.reset_password_form label {
  text-align: left !important;
}

.Registration_form .newreg_or_forgot {
  margin-top: 10px;
  margin-bottom: 0px;
}

/* .Registration_form h3 {
  margin-bottom: 30px;
} */

.Registration_form .fa,
.reset_password_form .fa {
  margin-left: -30px;
}

.Registration_form input,
.Registration_form select {
  border-radius: 50px;
}

.Registration_form button {
  margin-top: 30px;
}

.Registration_form .button h3 {
  font-weight: 600;
}

/*----css for registration page end-------------------*/

/*----css for ForgetPassword page start-------------------*/

.forget_password button {
  margin-top: 20px;
}

.forget_password .input_field_div1 {
  margin-top: 25px;
}

.forget_password .input_field_div1 input {
  padding-left: 35px;
}

.forget_password .input_field_div2 {
  margin-top: 40px;
}

/*----css for ForgetPassword page end-------------------*/

/*-----css for Application Process section start--------*/

.application_process_main {
  width: 100%;
  position: relative;
}

.application_process_nav {
  display: none;
}

/*------UserId start--------------*/

.userid_main {
  position: absolute;
  top: 30px;
  left: 30px;
}

.userid_main .user_profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px 0px #dddddd;
  margin-bottom: 50px;
  cursor: pointer;
}

.userid_main .user_profile .fa {
  font-size: 28px;
  color: #ededed;
}

.userid_main .user_detail {
  padding: 20px;
  box-shadow: 0px 0px 4px 0px #dddddd;
  border-radius: 20px;
  position: relative;
}

.userid_main .user_detail::before {
  position: absolute;
  top: -30px;
  left: 25px;
  content: " ";
  width: 35px;
  height: 30px;
  background-color: #f5f5f5;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.user_detail button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 200px;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 2px 0px #dddddd;
  font-weight: 600;
}

/*------UserId end--------------*/

/*------latest notification section start--------------*/

.latest_notification_main {
  width: 80%;
  margin: auto;
  padding: 0px 0px 30px 0px;
}

.notification_card_apply,
.notification_card {
  position: relative;
}

.notification_card_apply::before,
.notification_card::before {
  position: absolute;
  content: " ";
  top: 50%;
  left: 12px;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff7700;
}

.notification_card_apply,
.notification_card {
  padding: 10px 30px;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
  margin-bottom: 15px;
}

.new_notification_badge,
.new_badge {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0px;
  right: 0;
  padding: 5px 0px;
  background-color: yellow;
  clip-path: polygon(0 1%, 100% 0, 100% 100%);
  display: flex;
  justify-content: end;
  align-items: center;
  animation: bg-color 0.3s ease infinite;
  animation-duration: 4s;
}

.new_badge {
  width: 32px;
  height: 32px;
}

@keyframes bg-color {
  0% {
    background-color: yellow;
  }

  80% {
    background-color: orange;
  }
}

.new_notification_badge p,
.new_badge p {
  transform: rotate(45deg);
  font-size: 12px;
  color: #0d2750;
}

.new_badge p {
  font-size: 9px !important;
  margin-top: 2px;
}

.dashboard_view_data .new_badge p {
  margin-top: -10px;
}

.notification_subject h3 {
  color: #1e2343;
  font-size: 17px !important;
}

.notification_date_main {
  display: flex;
}

.notification_date_main p {
  margin-bottom: 0px;
}

.notification_date_main .end_date {
  margin: 0px 10px;
}

.card_right_col {
  padding: 2% 0px;
}

.notification_card .card_right_col {
  padding: 10px 0px 0px 0px;
}

.notification_time_date_main {
  display: flex;
  justify-content: end;
}

.notification_time_date_main .notification_time {
  margin-left: 10px;
  margin-top: 10px;
}

.notification_time_date_main .apply_btn button {
  padding: 5px 20px;
  border: 1px solid #c4c4c4;
  border-radius: 20px;
  font-weight: bold;
  background-color: #fff;
  color: #1e2343;
}

.apply_btn button:hover {
  background-color: #6d8dad;
  color: #fff;
}

/*------latest notification section end--------------*/

/*------My Application section start--------------*/

.my_application_main table {
  width: 70%;
  margin: auto;
  margin-top: 30px;
  overflow-x: auto;
  overflow-y: auto;
}

.my_application_main table thead {
  width: 100%;
  background-color: #f0f0f3;
}

.my_application_main table thead th {
  text-align: center;
}

.my_application_main table td {
  border-style: none;
  font-weight: bold;
}

.my_application_main table .table_info {
  margin: 10px 0px;
  text-align: center;
  border-right: 2px solid #dddddd;
}

.table_lastchild_info {
  border-right: none;
  text-align: center;
  margin: 10px 0px;
}

.table> :not(:first-child) {
  border-top: none;
}

/*------My Application section end--------------*/

/*----user milestones start---*/
.user_milestone_main {
  height: 55vh;
  overflow: scroll;
}

.user_milestone {
  width: 60%;
  margin: auto;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.user_milestone_header {
  padding: 5px 0px;
  text-align: center;
  background-color: #f0f0f3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.user_milestone_body {
  padding: 20px;
}

.user_milestone_header h4,
.user_milestone_body h4 {
  font-weight: 600;
}

.user_milestone_body p {
  margin-bottom: 0px;
  font-weight: 600;
}

.user_milestone_body a:hover {
  border-bottom: 1px solid #0d2750;
  cursor: pointer;
}

.user_milestone_select_fle {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.user_milestone_body button {
  padding: 3px 10px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  font-weight: 600;
}

.user_milestone_body button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.user_milestone_attached_file input:focus {
  box-shadow: none;
}

/*----user milestones end----*/

/*-----Milestone section start----------*/

.milestone_section {
  width: 80%;
  margin: auto;
  padding-top: 30px;
}

.milestone_section h3 {
  font-weight: bold;
}

.milestone_main {
  width: 100%;
  height: 300px;
  margin: auto;
  margin-top: 2%;
  /* background-image: url("milestone-bg/milestone_arrow_dark.png"); */
}

.milestone_list {
  width: auto;
  height: 300px;
  /* margin: 0px 30px; */
  display: inline-flex;
  /* overflow-x: scroll; */
  margin-top: 30px;
}

.milestone_div {
  width: 87%;
  margin: auto;
  overflow-x: scroll;
}

.milestone_name {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  padding: 6px;
  text-align: center;
  position: relative;
}

.milestone_name p {
  font-weight: bold;
  margin-bottom: 0;
}

.milestone_circle_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
}

.milestone_circle {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #039582;
  color: #0d2750;
  font-weight: bold;
  border-radius: 50%;
  border: 5px solid #1e2343;
}

.milestone_circle_main .verticle_line {
  width: 3px;
  height: 70px;
  background-color: #c4c4c4;
}

.milestone_status_color_code_main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.milestone_status_completed,
.milestone_status_inprogress,
.milestone_status_new_triggered,
.milestone_status_rejected {
  position: relative;
  margin: 0px 30px;
}

.milestone_status_completed::before,
.milestone_status_inprogress::before,
.milestone_status_new_triggered::before,
.milestone_status_rejected::before {
  position: absolute;
  content: " ";
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
}

.milestone_status_inprogress::before {
  background-color: yellow;
}

.milestone_status_new_triggered::before {
  background-color: gray;
}

.milestone_status_rejected::before {
  background-color: red;
}

.milestone_status_completed h4,
.milestone_status_inprogress h4,
.milestone_status_new_triggered h4,
.milestone_status_rejected h4 {
  margin-left: 20px;
  font-weight: bold;
}

.docUploadBtn {
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  font-weight: 600;
}

.docUploadBtn:hover {
  background-color: #6d8dad;
  color: #fff;
}

/*-----Milestone section end----------*/

/*-----Grievance section start-------*/

.grievance_main,
.myapplication_main,
.docsubmission_main,
.AlertNotification_main,
.Remittance_main {
  padding: 0px 0px 30px 0px;
  width: 100%;
  height: 500px;
}

.myapplication_main,
.user_refund .dashboard_back_office {
  padding: 30px 0px 30px 0px;
}

.grievance_main {
  padding-top: 0 !important;
}

.trigger_new_milestone {
  width: 80%;
  margin: auto;
  text-align: right;
  margin-top: 20px;
}

.trigger_new_milestone button {
  padding: 3px 10px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  font-weight: 600;
}

.trigger_new_milestone button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.Remittance_main {
  width: 95%;
  margin: auto;
}

.new_milestone_modal_box {
  width: 50% !important;
  max-height: 350px !important;
  overflow: scroll;
  top: 50% !important;
  z-index: 1;
}

.create_milestone_btn {
  text-align: right;
  margin-top: 20px;
}

.dialogue_box,
.new_milestone_modal_box {
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  margin: auto;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 25px;
  z-index: 11;
}

.new_milestone_modal_box .upload_marks_button a {
  cursor: pointer;
}

.upload_marks_button a:hover {
  border-bottom: 1px dotted #0d2750;
}

.user_marksheet_main {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.marks_detail_section {
  width: 100%;
  margin: 0;
  padding: 10px;
}

.marks_detail_section input {
  text-align: center;
}

.marks_detail_section .add_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}

.marks_detail_section h5 {
  margin-bottom: 5px;
}

.marks_detail_section .submit_marks_button {
  text-align: center;
}

.marks_detail_section .add_button button,
.marks_detail_section .submit_marks_button button {
  padding: 3px 10px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  font-weight: 600;
}

.marks_detail_section .add_button button:hover,
.marks_detail_section .submit_marks_button button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.marks_detail_section .delete_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.marks_detail_section .delete_button i {
  color: red;
  cursor: pointer;
}

.dialogue_box {
  width: 54%;
}

.other_documents_list,
.other_documents {
  display: flex;
  align-items: center;
}

.other_documents_list p {
  color: #0d2750 !important;
  font-weight: 600 !important;
}

.dialogue_box h4,
.new_milestone_modal_box h4 {
  font-weight: 600;
}

.acknowledge_dialogue_box_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 30px;
}

.acknowledge_dialogue_box_btn button,
.create_milestone_btn button,
.other_documents_list button,
.other_documents button,
.remove_btn button {
  padding: 3px 10px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  font-weight: 600;
}

.other_documents_list .fa {
  color: rgb(250, 36, 36);
}

.other_documents_list button:hover .fa {
  color: #fff;
}

.acknowledge_dialogue_box_btn button:hover,
.create_milestone_btn button:hover,
.other_documents_list button:hover,
.other_documents button:hover,
.remove_btn button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.tabel_heading,
.tabel_data {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  position: relative;
}

.tabel_heading {
  background-color: #f0f0f3;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tabel_data {
  margin-top: 15px;
}

.tabel_heading h4,
.tabel_data p {
  font-weight: 600;
}

.tabel_data a:hover {
  border-bottom: 1px solid #0d2750;
  cursor: pointer;
}

.data {
  border-right: 2px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.align {
  display: flex;
  align-items: center;
}

.AlertNotification_card_title {
  text-align: left;
  padding-left: 20px !important;
}

.grievance_btn {
  width: 80%;
  margin: auto;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grievance_btn button {
  padding: 5px 10px;
  background-color: #fff;
  color: #0d2750;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  font-weight: bold;
}

/*-----grievance modal popup----*/

.grievance_modal {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.grievance_modal form {
  width: 40%;
  margin: auto;
  padding: 25px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}

.grievance_modal form h2 {
  text-align: center;
  margin: 10px 0px 25px 0px;
}

.grievance_modal form select,
.grievance_modal form input {
  border-radius: 20px;
}

.grievance_modal form .textarea {
  margin: 25px 0px;
}

.grievance_modal form textarea {
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
  padding: 10px;
}

.grievance_modal form textarea:focus {
  outline: none;
}

.grievance_form_btn {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.grievance_form_btn button {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #fff;
  color: #0d2750;
  font-weight: bold;
  border: 1px solid #c4c4c4;
}

.grievance_form_btn button:hover {
  background-color: #6d8dad;
  color: #fff;
}

/*-----Grievance section end-------*/

/*-----Doc submission section start--------*/

.upload_document_detail_main {
  width: 80%;
  margin: auto;
  margin-bottom: 15px;
  padding: 15px 15px 0px 15px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  border-top: none;
  margin-top: -20px;
}

.doc_header {
  width: 100%;
  background-color: #fff;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 20px;
}

.doc_detail_srno h3,
.doc_srno h3 {
  border-right: 2px solid #c4c4c4;
  font-weight: 600;
  text-align: center;
}

.doc_srno,
.doc_data_name {
  margin-top: 30px;
}

.doc_data_name h3 {
  font-weight: 600;
  color: #0d2750;
}

.choose_file,
.upload_btn button {
  margin-top: 25px;
}

.doc_name h3 {
  font-weight: 600;
  padding-left: 15px;
  text-align: left;
}

.doc_data {
  width: 100%;
  background-color: #fff;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
}

.doc_data .upload_btn button {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  color: #0d2750;
  font-weight: 600;
}

.uploaded_image {
  width: 100px;
  height: 100px;
  border: 1px solid #c4c4c4;
  margin-left: 20px;
}

.uploaded_image img {
  width: 100%;
  height: 100%;
}

/*-----Doc submission section end---------*/

/*-----Remittance section start------*/

.Remitance_card_heading {
  width: 90%;
  text-align: center;
  background-color: #f0f0f3;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.Remitance_card_heading h4 {
  font-weight: 600;
}

.Remitance_card_data_main {
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.Remitance_card_data {
  width: 90%;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  position: relative;
}

.Remitance_card_data p {
  font-weight: 600;
}

.Remitance_card_data .data {
  border-right: 2px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.aknowledge_btn {
  width: 10%;
}

.aknowledge_btn button {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  color: #0d2750;
  font-weight: bold;
}

/*-----Remittance section end-----*/

/*-----css for Application Process section end--------*/

/*------css for mobile menu start---------*/

.mobile_menu_main {
  width: 100%;
  position: absolute;
  top: 4%;
  left: 0;
  display: none;
  z-index: 11;
}

.mobile_menu_main .hamburger {
  padding: 20px 0px 0px 20px;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.mobile_menu_list {
  position: relative;
  width: 100%;
  background-color: #1b203f;
  margin-top: -36px;
}

.mobile_menu_list .close_btn {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.mobile_menu_list ul {
  list-style: none;
  padding: 50px 0px;
}

.mobile_menu_list ul li {
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 81px;
}

/*------css for mobile menu end-----------*/

/*-----css for mobile gallery start-----*/

.mobile_img_gallery {
  width: 95%;
  height: auto;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  display: none;
}

.download_user_button {
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  width: 100px;
  padding: 5px;
  margin-left: 20px;
  border: 1px solid #c4c4c4;
  border-radius: 50px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  cursor: pointer;
}

.download_user_button:hover {
  background-color: #6d8dad;
}

.download_user_button:hover h4 {
  color: #fff;
}

/*-----css for mobile gallery end-----*/

/***********************************************/

/*---Application Form- Personal details start--*/

/***********************************************/

.personal_details_main {
  margin: 30px 0px;
}

.personal_details_main label,
.college_admission_detail_form label {
  font-size: 14px;
  color: #7e7e7e;
}

.personal_details_main h4,
.college_admission_detail_form h4 {
  color: #0d2750;
  font-weight: bold;
  margin-top: 3px;
}

.personal_details_main input,
.college_admission_detail_form input {
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.personal_details_main select,
.college_admission_detail_form select {
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.personal_details_main button,
.family_income_details_main button {
  color: #070707;
  font-weight: 600;
  border: 1px solid rgba(112, 112, 112, 0.3);
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
  background-color: #fff;
}

.personal_details_main button:hover,
.family_income_details_main button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.extension {
  margin-left: 15px;
}

.code_extension {
  display: flex;
  align-items: center;
}

.code_extension input {
  text-align: center;
}

.code_extension span {
  margin-right: 5px;
  font-weight: bold;
  color: rgb(116, 114, 114);
}

.personal_details_main .form-check-input {
  border-radius: 20%;
}

.personal_details_main textarea {
  min-height: 50px;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.form_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
}

.form_button button {
  color: #0d2750;
  border: 1px solid #b4b3b3;
  background-color: #fff;
  font-weight: 600;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.form_button button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.form_button .form_save-btn {
  margin: 0px 50px;
  padding: 6px 30px;
}

.form_button .form_cancel_btn {
  padding: 6px 30px;
}

.educationButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.educationButton button {
  border: none;
  padding: 0 10px;
}

.form_preview_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

/***********************************************/

/***********Family Income Details Start *********/

.family_income_details_main {
  margin: 30px 0px;
}

.family_income_details_main H4 {
  color: #0d2750;
  font-weight: bold;
  font-size: 16px;
}

.family_income_details_main label {
  font-size: 14px;
  color: #7e7e7e;
}

.family_income_details_main input {
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.family_income_details_main .form-check-input {
  border-radius: 20%;
}

/**************************************************/

/**************Family Detail Form Start***********/

.family_details_main {
  margin-top: 30px;
}

.family_details_main label {
  font-size: 13px;
  color: #7e7e7e;
}

.family_details_main input,
.family_member_details_table input,
.family_member_availed_scholarship_details_table input,
.education_expenses_detail_form input {
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
  text-align: center;
}
.family_member_details_table{
  font-size: 11px;
}

.family_details_main .form-check-input,
.education_expenses_detail_form .form-check-input {
  border-radius: 20%;
}

.field_add_btn,
.scholarship_availed_add_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.scholarship_availed_add_btn {
  margin-top: 0;
}

.scholarship_availed_add_btn button {
  margin-left: -45px;
}

.field_add_btn button,
.scholarship_availed_add_btn button {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
  border: 1px solid #b4b3b3;
}

.field_add_btn button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.scholarship_availed_form h4 {
  margin-bottom: 20px;
  color: #0d2750;
  font-weight: bold;
  font-size: 16px;
}

.trash_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trash_btn button {
  border: 1px solid #b4b3b3;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.trash_btn button .fa {
  border: none;
  color: rgb(255, 0, 0);
}

/**************************************************/

/**************College admission Detail Form Start***********/

.college_admission_detail_form,
.education_expenses_detail_form {
  margin-top: 30px;
}

/**************************************************/

/**************Education expenses Detail Form Start***********/

.education_expenses_detail_form form {
  margin-top: 30px;
}

.education_expenses_detail_form h4 {
  color: #0d2750;
  font-weight: bold;
  font-size: 16px;
}

.education_expenses_detail_form label {
  font-size: 14px;
  color: #7e7e7e;
}

.total_expenses {
  position: relative;
}

.total_expenses span {
  position: absolute;
  top: 10px;
  left: 10px;
}

.total_expenses span .fa {
  color: #b4b3b3;
  font-size: 18px;
}

.education_expenses_label {
  display: none;
}

.input_field_label {
  display: none;
}

/**************************************************/

/**************Document submission Detail Form Start***********/

.document_submission_main {
  margin: 30px 0px;
}

.document_submission_main p {
  color: #0d2750;
  font-weight: 600;
}

.document_submission_main h4 {
  color: #0d2750;
  font-weight: bold;
  font-size: 16px;
}

.document_submission_main .select_file {
  margin-top: 10px;
}

.document_submission_main .select_file input {
  margin-top: 8px;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.document_submission_main .upload_btn {
  margin-top: 27px;
}

.document_submission_main .upload_btn button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #b4b3b3;
  color: #0d2750;
  font-weight: 500;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.document_submission_main .doc_image {
  height: 160px;
  padding: 0;
  border: 1px solid #b4b3b3;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
  cursor: pointer;
}

.other_documents {
  margin-top: 50px;
}

.other_documents .select_file {
  margin-top: 50px;
}

.other_documents .upload_btn {
  margin-top: 35px;
}

.document_submission_main .document_name {
  border: none;
  color: #0d2750;
  font-size: 17px;
  font-weight: bold;
}

.other_documents .doc_image {
  width: 140px;
  height: 160px;
  margin-left: 45px;
}

.document_submission_main .pdf_preview {
  margin: 10px;
}

.document_submission_main .pdf_preview img {
  width: 100%;
  height: 100%;
}

.document_preview_modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.507);
  z-index: 999;
  overflow-y: scroll;
}

.document_preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

.document_preview_modal span {
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
}

.document_preview_modal span .fa {
  color: #fff;
  font-size: 18px;
}

.document_preview_modal img {
  width: 100%;
  height: 100%;
}

/*-------Loading spinner----------*/

.loading_spinner {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spinner h3 {
  margin-right: 10px;
  color: #0d2750;
  letter-spacing: 2px;
  font-size: 12px !important;
}

.myapplication_card_data a {
  cursor: pointer;
}

.myapplication_card_data a:hover {
  border-bottom: 1px solid #070707;
}

.form-control {
  border: 1px solid rgba(112, 112, 112, 0.5);
}

.form-select {
  border: 1px solid rgba(112, 112, 112, 0.5);
}

.form-check-input {
  border: 1px solid rgba(112, 112, 112, 0.5);
}

/* Application Flow for big size page*/

.application_flow_big {
  position: relative;
  height: 62vh;
  background-image: url("/src/bg_img.png");
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: 100% auto;
  /* Resize the background image to cover the entire container */
  overflow-y: scroll;
}

.user_application_flow img {
  width: 100%;
  height: 100%;
}

.downloadinfo_card_apply {
  padding: 10px 30px;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 1px 2px 0px #d5d6d8;
  margin-top: 15px;
  height: auto;
}

.downloadinfo_card_apply ul {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0;
}

.downloadinfo_card_apply .info_align {
  display: flex;
  align-items: center;
}

/* 
=========================================================
* Admin Portal Start
========================================================= 
*/

.admin_home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin_main_section {
  position: relative;
}

.Admin_profile_tab {
  position: absolute;
  top: 1%;
  right: 1%;
}

.Admin_profile_tab a {
  cursor: pointer;
}

.Admin_profile_tab i {
  font-size: 35px;
}

.logout_box_admin span {
  position: absolute;
  left: 85%;
  cursor: pointer;
}

.logout_box_admin {
  z-index: 1;
  width: 18%;
  height: 17vh;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
  position: absolute;
  top: 11%;
  right: 2%;
  background-color: white;
}

.logout_box_admin .button {
  padding: 2px 20px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  border: 1px solid #707070;
  color: #1e2343;
  background-color: #fff;
  position: absolute;
  left: 10px;
  bottom: 6px;
}

.logout_box_admin .button:hover {
  background-color: #6d8dad;
  transition: 0.3s ease;
  border: none;
}

.logout_box_admin .button:hover h5 {
  color: #fff;
}

.logout_box_admin h5 {
  font-size: 12px;
  color: gray;
}

.logout_box_admin h3 {
  font-size: 10px;
  font-weight: bold;
}

.side_navbar {
  /* overflow-y: scroll; */
  height: 70vh;
}

.sidebar_parent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.sidebar_child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* right: -5px; */
  overflow-y: scroll;
}

.sidebar_child h5:hover {
  color: white;
}

.admin_tab h5 {
  font-size: 10px;
}

/* .sidebar_header {
  margin: auto;
  margin-top: 15px;
  width: 90%;
  height: 15vh;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
}

.sidebar_header .button {
  height: 20px;
  padding: 0px 20px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  border: 1px solid #707070;
  color: #1e2343;
  background-color: #fff;
  position: relative;
}

.sidebar_header .button:hover {
  background-color: #6d8dad;
  color: #fff;
  transition: 0.3s ease;
  border: none;
}

.sidebar_header h5 {
  font-size: 12px;
  color: gray;
  padding-top: 7px;
}

.sidebar_header h3 {
  font-size: 10px;
  font-weight: bold;
}
 */

.sidebar_tab {
  margin: auto;
  margin-top: 15px;
  width: 90%;
  height: 6vh;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
  justify-content: center;
  display: flex;
  align-items: center;
}

.sidebar_tab_selected {
  margin: auto;
  margin-top: 15px;
  width: 90%;
  height: 6vh;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #0d6efd;
  color: #fff;
}

.dropdown-button {
  width: 65%;
  height: 4vh;
}

.dropdown-button-selected {
  width: 65%;
  height: 4vh;
  background-color: #0d6efd;
  color: #ffffff;
}

.sidebar_tab:hover {
  background-color: #0d6efd;
  cursor: pointer;
}

.sidebar_tab h5 {
  font-size: 18px;
}

/*///////////////////////////
///// Dashboard - View //////
//////////////////////////*/

.dashbord_main {
  width: 95%;
  /* padding: 30px 0px 30px 0px; */
  padding: 0;
  position: relative;
}

.search_wrapper {
  margin: 10px 0;
}

.Remittance_main .search_wrapper {
  width: 90%;
}

.docsubmission_main .search_wrapper,
.AlertNotification_main .search_wrapper {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_wrapper input:focus {
  outline: none;
  box-shadow: none;
}

.search_wrapper .Front_Office_Btn {
  justify-content: flex-start;
}

.update_statistics_title,
.download_statistics_title {
  margin: 8px 0 15px 0;
}

.dashboard_view_data_heading {
  width: 100%;
  text-align: center;
  background-color: #f0f0f3;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 20px;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.dashboard_view_data_heading h4,
.dashboard_view_data_main p {
  font-weight: 600;
}

.dashboard_view_data_main {
  width: 100%;
  display: flex;
}

.dashboard_view_data {
  width: 100%;
  position: relative;
}

/* .dashboard_view_data_main h3 {
    font-size: 16px;
} */

.dashboard_background {
  height: 70vh !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dashboard_background_update {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.button_div {
  margin-top: 20px;
  margin-left: auto;
}

.dashboard_Delete_button {
  height: 10vh;
  margin-top: 20px;
}

.dashboard_delete_button button {
  color: #0d2750;
  border: 1px solid #b4b3b3;
  background-color: #fff;
  font-weight: 600;
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.dashboard_delete_button textarea {
  box-shadow: 2px 3px 7px 0px rgba(13, 39, 80, 0.2);
}

.dashboard_delete_button button:hover {
  background-color: #6d8dad;
  color: #fff;
}

.dashboard_delete_button {
  display: flex;
  align-items: center;
}

.dashboard_view_data {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* .sr-no h5 {
   padding-right: 20px; 
} */

.sr-no input {
  margin-right: 20px;
  /* margin-left: 25px; */
}

.sr-no span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view_dashboard {
  border-right: 2px solid #c4c4c4;
}

.view_dashboard a,
.grievance_card_data a {
  cursor: pointer;
}

.view_dashboard a:hover,
.grievance_card_data a:hover {
  border-bottom: 1px solid #070707;
}

.dashbord_input input {
  width: 50%;
  margin: auto;
  height: 30px;
}

/*///////////////////////////
///// Dashboard - Create ////
//////////////////////////*/

.Checkbox-inline {
  display: flex;
  text-align: center;
  align-items: center;
}

.Checkbox-inline h5 {
  margin-left: 5px !important;
}

/* .create_form {
  width: 100%;
  padding: 2% 0%;
} */

.create_form {
  width: 65%;
  height: auto;
  margin: auto;
  background-color: #ececec;
  text-align: center;
  padding: 2%;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgba(13, 39, 80, 0.192);
  margin-top: 35px;
  /* margin-left: 30px; */
}

.create_form form label {
  text-align: start;
}

.create_form form label h3 {
  margin-left: 30px;
}

.create_form form h1 {
  font-size: 18px;
  font-weight: 600;
}

.create_form form h3 {
  font-size: 12px;
}

.create_form form input,
.create_form form select {
  height: 30px;
  font-size: 12px !important;
  font-weight: bold;
  padding-left: 20px;
}

.create_form form textarea {
  font-size: 12px !important;
  font-weight: bold;
  padding-left: 20px;
}

.create_form .button {
  height: 30px;
  padding: 0px 20px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  border: 1px solid #707070;
  color: #1e2343;
  background-color: #fff;
  position: relative;
}

.create_form .button:hover {
  background-color: #6d8dad;
  color: #fff;
  transition: 0.3s ease;
  border: none;
}

.arrayFetch h3 {
  font-size: 10px;
}

.Designation-select-box-form {
  width: 100%;
}

.user_password_field {
  position: relative;
}

.user_password_field span {
  position: absolute;
  top: -6px;
  right: -22px;
}

.user_password_field .showPass {
  position: absolute;
  top: -3px;
  right: -9px;
}

/*----------css for spinner--------*/

.spinner_circle_main {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
}

.inner_spinner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border-right: 2px solid rgba(255, 255, 255, 0.6);
  border-top: 2px solid blue;
  border-left: 2px solid blue;
  border-bottom: 2px solid blue;
  animation: rotate_spinner 1.6s infinite;
}

@keyframes rotate_spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*------css for text modal----------*/

.modal_body {
  text-align: left;
  padding-left: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #00c2a8;
  border-radius: 10px;
  background-color: #fff;
  margin: 6px;
  box-shadow: 0px 3px 3px 0px #5384b8;
}

.modal_body h4 {
  margin-bottom: 8px;
  color: #0d2750;
}

.modal-content {
  width: 80%;
  margin: auto;
  background-color: #849bb3;
  border-radius: 10px;
  border: 1px solid #849bb3;
}

.modal_footer {
  text-align: center;
  justify-content: center;
  border: none;
  padding: 5px;
}

.modal_footer .modal_button {
  background-color: #03a892;
  border: 1px solid #00c2a8;
}

.modal_footer .modal_button:hover {
  background-color: #039582;
}

.download_excel {
  margin: 5%;
}

.download_excel a:hover {
  border-bottom: #0d6efd;
}

.download_excel h3 {
  margin-top: 20px;
  color: #0d6efd;
  cursor: pointer;
}

.invaild_message_array {
  display: block;
  color: red;
  font-size: 0.875em;
}

/* 
=========================================================
* Admin Portal End
========================================================= 
*/

/* 
=========================================================
* Front Office Start
========================================================= 
*/

.dashboard_Front_office,
.dashboard_back_office {
  padding: 0px;
  position: relative;
}


.dashboard_background {
  height: 60vh;
  overflow-y: scroll;
}

.dashboard_background_2 {
  height: 50vh;
  overflow-y: scroll;
}

.Front_Office_Btn {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 10px; */
  gap: 15px;
}

.Front_Office_Btn .dashboard_delete_button {
  margin-right: 0px !important;
}

.Front_Office_logout {
  width: 90%;
  margin-top: 0;
}

.Front_Office_logout .button {
  height: 20px;
  padding: 0px 20px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  border: 1px solid #707070;
  color: #1e2343;
  background-color: #fff;
  position: relative;
}

.Front_Office_logout .button:hover {
  background-color: #6d8dad;
  color: #fff;
  transition: 0.3s ease;
  border: none;
}

.Front_Office_logout h3 {
  font-size: 10px;
  font-weight: bold;
}

.Front_office_sr_no h3 {
  padding-right: 15px;
}

.Front_office_sr_no input {
  margin-left: 20px;
  margin-right: 10px;
}

.Front_office_sr_no span {
  display: flex;
  align-items: center;
}

.profile_tab {
  position: absolute;
  top: 0px;
  left: 10px;
  height: 12px;
  z-index: 991;
  margin-top: 2px;
}

.profile_tab a {
  cursor: pointer;
}

.profile_tab i {
  font-size: 35px;
}

.rating_radio {
  margin: 10px;
}

.rating_div {
  padding-top: 16px;
}

.rating_radio h3 {
  font-size: 16px;
}

.logout_box_front span {
  position: absolute;
  left: 85%;
  cursor: pointer;
}

.logout_box_front {
  margin-left: 7px;
  width: 15%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px #d5d6d8;
  position: absolute;
  top: 42px;
  z-index: 991;
  background-color: white;
}

.logout_box_front .button {
  padding: 5px 20px;
  left: 10px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  border: 1px solid #707070;
  color: #1e2343;
  background-color: #fff;
  margin-bottom: 10px;
  margin-top: 15px;
}

.logout_box_front .button:hover {
  background-color: #6d8dad;
  color: #fff;
  transition: 0.3s ease;
}

.logout_box_front h5 {
  font-size: 12px;
  color: gray;
  padding-top: 7px;
}

.logout_box_front h3 {
  font-size: 10px;
  font-weight: bold;
}

.dashboard_delete_button h3 {
  font-size: 10px;
}

.mom_button {
  right: 0;
}

.mom_box {
  position: absolute;
  top: 35%;
  left: 78%;
  width: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.mom_first span {
  position: absolute;
  left: 78%;
  cursor: pointer;
}

/*--------css for Back office start--------------*/

.select_committee {
  width: 70%;
  margin: auto;
  margin-bottom: 30px;
}

.select_committee select {
  border-radius: 50px;
}

.back_office_nav_list li {
  margin: 0px 13px;
  font-size: 18px !important;
}

.back_office_preview_section,
.user_grievance_chatbot_section {
  padding: 30px 0px;
}

.back_office_preview_box,
.user_grievance_chatbot_box {
  position: relative;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 2px 3px 0px #cccccc;
  min-height: 300px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.back_office_preview_box p {
  font-weight: 600;
}

.back_office_preview_title,
.user_grievance_chatbot_title {
  text-align: center;
  background-color: #f0f0f3;
  padding: 8px 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid #c4c4c4;
  position: sticky;
  top: 0;
  z-index: 1;
}

.close_back_office_preview,
.close_user_chatbot {
  position: absolute;
  top: 6px;
  right: 8px;
  padding: 0px 6px;
  background-color: #98ccfd;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid #4788c7;
}

.chatbot_searchbox_main {
  position: relative;
}

.chatbot_search_box,
.chatbot_search_icon {
  position: absolute;
  top: -3px;
  right: 20%;
}

.chatbot_search_icon {
  right: 41%;
  margin-top: 3px;
  cursor: pointer;
}

.chatbot_search_box input {
  border: 1px solid rgba(112, 112, 112, 0.5);
  height: 30px;
}

.chatbot_search_box input:focus {
  border: 1px solid rgba(112, 112, 112, 0.5);
  box-shadow: none;
}

.close_back_office_preview .fa,
.close_user_chatbot .fa {
  color: #fff;
}

.back_office_document_preview {
  /* min-height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.select_to_assign {
  display: flex;
  justify-content: end;
  align-items: center;
}

.Assign_section {
  text-align: right;
  margin: 30px 0px 0px 0px;
}

.Assign_section select {
  width: 84%;
  margin-left: 10px;
}

.back_office_preview_section .file_name {
  text-align: right;
  margin-top: 10px;
}

.back_office_preview_section h3,
.back_office_preview_section h4 {
  font-weight: bold;
  color: #0d2750;
}

.back_office_preview,
.user_grievance_chatbot_comment_main {
  position: relative;
  padding: 10px 0px;
}

.back_office_preview_comment_section,
.user_grievance_chatbot_comment_section {
  height: 350px;
  overflow-y: scroll;
  margin-bottom: 20px;
  overflow-x: hidden;
  padding: 0px 50px;
}

.back_office_preview .user_msg_main,
.back_office_user_msg_main,
.back_office_user_message_main,
.user_message_main,
.attached_dacument_section,
.attached_file {
  width: 50%;
  padding: 8px 15px;
  border: 1px solid #c4c4c4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 0px 3px 6px 0px #dbdada; */
}

.attached_dacument_section {
  width: 30%;
  padding: 0;
}

.attached_file {
  width: 100%;
  height: 150px;
  padding: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #c4c4c4;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attached_file .fa {
  font-size: 5rem;
  color: red;
  cursor: pointer;
}

.attached_file_with_msg {
  padding: 5px;
}

.back_office_user_msg_main {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
}

.back_office_preview .user_detail,
.back_office_user_detail,
.user_back_office_detail,
.user_message_main .user_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.back_office_preview_comment_section p .user_grievance_chatbot_comment_section p {
  margin-bottom: 0;
  font-size: 13px;
}

.back_office_user_textarea_main,
.user_chatbot_textarea_main {
  width: 100%;
  /* position: absolute; */
  bottom: 10px;
  padding: 0px 50px;
}

.back_office_user_textarea,
.user_chatbot_textarea {
  display: flex;
  align-items: center;
}

.user_chatbot_text_file {
  width: 70%;
  position: relative;
}

.user_chatbot_text_file .input-group {
  position: absolute;
  bottom: 0;
  right: 10px;
  bottom: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #00c2a8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.user_chatbot_text_file .attach_file {
  z-index: 1;
}

.user_chatbot_text_file .attache_file_name {
  position: absolute;
  bottom: 0;
  right: 50px;
  bottom: 10px;
  background-color: #fff;
}

.message_form_validation {
  color: red;
  margin-top: 3px;
  font-size: .875em;
}

.back_office_user_textarea button,
.user_chatbot_textarea button {
  margin-top: 25px;
  margin-left: 20px;
}

.user_chatbot_text_file .input-group .fa {
  margin-left: 0 !important;
  font-size: 18px;
  cursor: pointer;
}

.user_chatbot_text_file .input-group::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.user_chatbot_text_file .input-group:hover::after {
  width: 40px;
  height: 40px;
  background-color: #00c2a8;
  transition: 0.6s ease;
  z-index: -1;
}

.back_office_user_textarea textarea {
  border: 1px solid #c4c4c4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 0px 3px 6px 0px #dbdada; */
}

.dashboard_back_office .dashboard_view_data_main h4 {
  font-size: 15px !important;
  font-weight: bold;
}

.back_office_button {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 30px 0px;
}

.back_office_button h5,
.minOfMeeting h5 {
  padding: 4px;
}

.minOfMeeting button,
.back_office_button button,
.back_office_button label,
.upload_home_verification_section label,
.upload_home_verification_button button,
.back_office_user_textarea button,
.user_chatbot_textarea button,
.upload_report_section button,
.Assign_section button,
.upload_home_verification button,
.send_email_to_university_button button,
.home_verification_button button,
.upload_files_btn button {
  padding: 3px 20px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #c4c4c4;
  color: #0d2750;
  background-color: #fff;
  box-shadow: 0px 2px 3px 0px #cccccc;
  cursor: pointer;
}

.minOfMeeting button:hover,
.back_office_button button:hover,
.back_office_button label:hover,
.upload_home_verification_section label:hover,
.upload_home_verification_button button:hover,
.back_office_user_textarea button:hover,
.user_chatbot_textarea button:hover,
.upload_report_section button:hover,
.upload_home_verification button:hover,
.home_verification_button button:hover,
.Assign_section button:hover,
.upload_files_btn button:hover,
.send_email_to_university_button button:hover {
  color: #fff;
  background-color: #0d6efd;
  transition: 0.3s ease-in;
}

.back_office_button button a {
  text-decoration: none;
  color: #0d2750;
}

.back_office_button button a:hover {
  color: #fff;
}

.send_email_to_university input {
  margin: 15px 0px;
}

.send_email_to_university_button {
  text-align: right;
  margin-top: 15px;
}

.student_Performance_section .back_office_search_box {
  width: 100%;
}

.student_Performance_section .back_office_search_box .search_box {
  width: 25%;
  margin: auto;
}

.back_office_search_box .search_box {
  position: relative;
  margin-bottom: 15px;
}

.back_office_search_box .search_box .fa {
  position: absolute;
  top: 50%;
  right: 6%;
  transform: translate(-50%, -50%);
  color: #c1c1c1;
}

.grievance_send_msg_button {
  text-align: right;
  margin-top: 3px;
}

.grievance_send_msg_button button {
  padding: 3px 20px;
  border-radius: 10px;
  font-weight: 700;
  border: 1px solid #c4c4c4;
  color: #0d2750;
  background-color: #fff;
}

.grievance_send_msg_button button:hover {
  background-color: #5384b8;
  color: #fff;
}

.individual_msg_form_main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 70vh;
  background-color: rgba(0, 0, 0, 0.623);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.individual_msg_form {
  width: 45%;
  margin: auto;
  padding: 2%;
  background-color: #fff;
  border-radius: 10px;
}

.individual_msg_form_main .user_chatbot_textarea_main {
  padding: 0;
  margin-top: 20px;
}

.individual_msg_form_main .individual_form_button {
  margin-top: 20px;
  text-align: center;
}

.individual_msg_form_main .individual_form_button button {
  padding: 3px 20px;
  border-radius: 10px;
  font-weight: 700;
  border: 1px solid #c4c4c4;
  color: #0d2750;
  background-color: #fff;
}

.individual_msg_form_main .individual_form_button button:hover {
  background-color: #5384b8;
  color: #fff;
}

.individual_msg_form_main .user_chatbot_text_file {
  width: 100%;
}

.searchWrapper {
  padding: 4px;
  border: 1px solid #c4c4c4 !important;
}

.icon_down_dir i {
  font-size: 20px;
}

.multiSelectContainer .icon_down_dir:before {
  display: none;
}

.multiSelectContainer input {
  margin-top: 0 !important;
}

.uploaded_file_description {
  text-align: center;
  background-color: #f0f0f3;
  padding: 8px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 20px;
}

.uploaded_file_description h3 {
  font-size: 16px !important;
  font-weight: bold;
}

.document_scrutiny_uploaded_files_section {
  padding: 30px 0px;
}

.uploaded_documents {
  position: relative;
  min-height: 200px;
  border-radius: 5px;
  padding: 15px 0px;
}

.uploaded_documents h4 {
  font-size: 15px !important;
  font-weight: 600;
}

.uploaded_documents button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.uploaded_documents a,
.doc_submission_milestone a {
  cursor: pointer;
}

.uploaded_documents a:hover,
.doc_submission_milestone a:hover {
  border-bottom: 1px solid #070707;
}

.comment_box_heading {
  margin: 20px 0px 10px 0px;
}

.comment_box_heading p {
  font-weight: 600;
}

.comment_to_show {
  width: 80%;
  margin: auto;
  overflow: auto;
  margin-bottom: 15px;
}

.comment_to_write {
  position: relative;
  width: 75%;
}

.comment_to_write input {
  min-height: 80px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}

.comment_to_write input:focus {
  box-shadow: none;
}

.comment_to_write input::placeholder {
  position: absolute;
  top: 5px;
}

.comment_to_write button {
  position: absolute;
  top: 70%;
  left: 86%;
  transform: translate(-50%, -50%);
  padding: 3px 20px;
  border-radius: 10px;
  font-weight: 700;
  border: 1px solid #c4c4c4;
  color: #0d2750;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment_to_write button:hover {
  background-color: #0d6efd;
  color: #fff;
}

.comment_to_write .fa {
  margin-left: 5px;
  transform: rotate(60deg);
}

.upload_home_verification_section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.upload_home_verification_section .file_name {
  margin-left: 20px;
  margin-top: 0;
}

.upload_home_verification_button {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 30px;
}

.upload_report_section {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.back_office_notification {
  width: 100%;
  padding: 0px;
  height: 60vh;
  overflow: scroll;
}

.back_office_notification .search_box_section {
  margin-bottom: 15px;
}

.back_office_notification .search_box {
  position: relative;
  padding: 0;
}

.back_office_notification .search_box .fa {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  color: #c1c1c1;
}

.back_office_notification .delete_notification_icon {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.back_office_notification .delete_notification_icon .fa {
  font-size: 24px;
  color: #c1c1c1;
}

.back_office_notification .notification_card {
  margin-bottom: 0;
}

.back_office_notification .card_right_col {
  padding: 0;
}

.back_office_notification .notification_checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back_office_notification .notification_checkbox input {
  width: 17px;
  height: 17px;
}

.back_office_notification h3 {
  font-size: 16px !important;
}

.back_office_notification h4 {
  font-size: 15px !important;
}

.remittance_preview_section {
  padding: 30px 0px;
}

.remittance_preview,
.student_marks_detail {
  width: 70%;
  margin: auto;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 3px 6px 0px #cccccc;
}

.remittance_preview_section .back_office_document_preview {
  min-height: auto;
  text-align: left;
}

.student_marks_detail .form {
  width: 100%;
}

.student_marks_detail .select {
  width: 70%;
  margin: auto;
}

.student_marks_detail input {
  text-align: center;
}

.remittance_preview_section .form {
  width: 65%;
}

.remittance_preview_section .form h4 {
  font-size: 16px;
  color: #0d2750;
  font-weight: bold;
}

.gr_resolution {
  background-color: white;
  width: 99%;
  margin: auto;
  margin-top: 2px;
  margin-bottom: 25px;
}

table,
th,
td {
  border: 1px solid black;
}

.gr_heading {
  text-align: center;
}

.gr_heading,
table {
  width: 100%;
}

.gr_list_heading th {
  background-color: #c7edfd;
}

.remittance_preview_section .back_office_document_preview {
  min-height: auto;
  text-align: left;
}

.remittance_preview_section .form {
  width: 80%;
  margin: 20px 0px;
}

.remittance_preview_section .form h4 {
  font-size: 16px;
  color: #0d2750;
  font-weight: bold;
}

.remittance_preview_section button,
.back_office_remittance_preview_btn label {
  padding: 3px 20px;
  border-radius: 10px;
  font-weight: 700;
  border: 1px solid #c4c4c4;
  color: #0d2750;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px #cccccc;
  cursor: pointer;
}

.remittance_preview_section button:hover,
.back_office_remittance_preview_btn label:hover {
  color: #fff;
  background-color: #0d6efd;
  transition: 0.3s ease-in;
}

.back_office_remittance_preview_btn {
  display: flex;
  align-items: center;
  justify-content: end;
}

.back_office_remittance_receipt_main {
  border-radius: 5px;
  /* padding: 8px; */
}

.back_office_remittance_upload_receipt {
  display: flex;
  align-items: center;
}

.back_office_remittance_print_preview_section {
  margin: 30px 0px;
}

.back_office_remittance_print_preview_section table h4 {
  font-weight: 600;
}

.remittance_print_preview_header h4 {
  text-align: center;
  font-weight: 600;
}

.back_office_remittance_print_preview_section table {
  width: 100%;
}

.remittance_print_preview_table_left {
  width: 6%;
  text-align: center;
}

.remittance_print_preview_table_right {
  width: 20%;
  text-align: center;
}

.remittance_print_preview_table_data {
  width: 24%;
}

.remittance_print_preview_table_address {
  height: 60px;
}

.refund_preview_comment h4,
.refund_preview_total_amount h4 {
  font-weight: bold;
  font-size: 15px !important;
}

.gr_list_heading p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}

.gr_list_heading h5 {
  text-align: center;
}

.quota_preview_title {
  text-align: center;
  background-color: #f0f0f3;
  padding: 8px 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid #c4c4c4;
}

.close_quota_preview {
  position: absolute;
  top: 6px;
  right: 8px;
  padding: 0px 6px;
  background-color: #98ccfd;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid #4788c7;
}

.toatal_qouta_main {
  display: flex;
  justify-content: end;
  margin: 30px;
}

.toatal_qouta_main h5 {
  margin-right: 10px;
  padding: 3px;
}

.total_qouta_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total_qouta_box {
  width: 100px;
  border: 1px solid #c4c4c4;
  border-radius: 15px;
}

.gr_list_heading h5 {
  text-align: center;
}

.dashboard_view_data a {
  cursor: pointer;
}

.dashboard_view_data p {
  margin-bottom: 0;
}

.dashboard_view_data i {
  font-size: 1.3rem;
}

.dashboard_view_data .text_align,
.dashboard_view_data_heading .text_align,
.myapplication_card_data .text_align,
.docsubmission_card_data .text_align,
.AlertNotification_card_data .text_align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_view_data .align,
.myapplication_card_data .align,
.docsubmission_card_data .align,
.AlertNotification_card_data .align {
  display: flex;
  align-items: center;
}

/**********************************************/

/****** CHART START *****/

.amount_disbursed {
  background-color: white;
  border-radius: 5px;
  width: auto;
  min-height: 300px;
  padding: 20px;
  margin-top: 10px;
}

.graph_heading_div {
  margin-bottom: 10px;
}

.graph_heading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph_heading select {
  width: 100px;
  margin-left: 10px;
  padding: 0 !important;
}

.privacy_policy p {
  text-align: justify;
}

/* Merit List width */

.wholeTable {
  width: 100%;
  height: 60vh;
  margin: auto;
  overflow: scroll;
}

.frontOfficeTableHeader {
  display: inline-flex;
  text-align: center;
  background-color: #f0f0f3;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 20px;
  position: sticky;
  top: 0px;
}

.frontOfficeTableHeaderRow {
  width: 200px;
}

.frontOfficeTableBody {
  display: inline-flex;
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 10px;
}

.frontOfficeTableBodyRow {
  width: 200px;
}

.tableRowRightBorder {
  border-right: 2px solid #c4c4c4;
}

.srNo {
  width: 100px;
}

.srNo span {
  display: flex;
  justify-content: space-around;
}

.frontOfficeTableHeader .University {
  width: 200px;
}

.frontOfficeTableHeader .UniversityRank {
  width: 150px;
}

.frontOfficeTableHeader .Income {
  width: 100px;
}

.frontOfficeTableHeader .Course {
  width: 100px;
}

.frontOfficeTableHeader .Stream {
  width: 100px;
}

.frontOfficeTableHeader .Age {
  width: 100px;
}

.frontOfficeTableHeader .OtherScholar {
  width: 100px;
}

.frontOfficeTableHeader .percentage {
  width: 100px;
}

.frontOfficeTableHeader .Attempt {
  width: 100px;
}

.frontOfficeTableHeader .Status {
  width: 100px;
}

.frontOfficeTableHeader .Rating {
  width: 100px;
}

.frontOfficeTableBody .University {
  width: 200px;
}

.frontOfficeTableBody .UniversityRank {
  width: 150px;
}

.frontOfficeTableBody .Income {
  width: 100px;
}

.frontOfficeTableBody .Course {
  width: 100px;
}

.frontOfficeTableBody .Stream {
  width: 100px;
}

.frontOfficeTableBody .Age {
  width: 100px;
}

.frontOfficeTableBody .OtherScholar {
  width: 100px;
}

.frontOfficeTableBody .percentage {
  width: 100px;
}

.frontOfficeTableBody .Attempt {
  width: 100px;
}

.frontOfficeTableBody .Status {
  width: 100px;
}

.frontOfficeTableBody .Rating {
  width: 100px;
}

.applicationId span {
  cursor: pointer;
}

.applicationId a:hover {
  border-bottom: 1px solid rgb(13, 39, 80);
}

.view_download_dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.total_quota h4 {
  margin-top: 15px;
  font-weight: 700;
}

.graph_data_button button {
  color: #0d2750;
  border: 1px solid #b4b3b3;
  background-color: #fff;
  font-weight: 600;
  box-shadow: 2px 3px 7px 0px rgb(13 39 80 / 20%);
  border-radius: 3px;
}

.graph_data_button button:hover {
  background-color: #5384b8;
  color: #fff;
}

.firstLetterCap {
  text-transform: lowercase;
}

.firstLetterCap::first-letter {
  text-transform: capitalize;
}

/*----css for student performance tab-----*/
.student_performance_select_box {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.dashboard_view_data button,
.semester_list_cancel_btn button,
.reject_message_modal_btn button,
.student_marks_detail button {
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #c4c4c4;
  color: #0d2750;
  background-color: #fff;
  box-shadow: 0px 2px 3px 0px #cccccc;
}

.dashboard_view_data button:hover,
.semester_list_cancel_btn button:hover,
.reject_message_modal_btn button:hover,
.student_marks_detail button:hover {
  color: #fff;
  background-color: #0d6efd;
  transition: 0.3s ease-in;
}

.semester_list_cancel_btn {
  text-align: end;
}

.semester_list_cancel_btn button,
.reject_message_modal_btn button {
  padding: 3px 20px;
  border-radius: 10px;
  font-weight: 700;
}

.reject_message_modal_box {
  background-color: #fff;
  padding: 2%;
  width: 40%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  z-index: 1;
}

.reject_message_modal_btn {
  margin-top: 20px;
  text-align: end;
}

.student_Performance_section {
  width: 100%;
}

.university_list_main {
  position: relative;
}

.student_Performance_section .university_list {
  position: relative;
  width: 70%;
  margin: auto;
  border: 1px solid #c4c4c4;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
}

.student_Performance_section .university_list h4 {
  font-weight: 500;
}

.student_Performance_section .university_list i {
  position: absolute;
  top: 50%;
  left: 98%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.university_list_main .year_list {
  margin-left: 15%;
  width: 100%;
}

.university_list_main .year_list .year {
  margin: 5px 0;
}

.university_list_main .year_list p,
.student_list p {
  padding: 5px 0 5px 5px;
}

.university_list_main .year_list .year:hover,
.student_list:hover {
  background-color: rgb(240, 240, 243);
}

.Student_list_main {
  width: 80%;
}

.Student_list_main .student_list {
  width: 25%;
}

.university_list_main .year_list .year,
.student_list {
  position: relative;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 15%;
}

.university_list_main .year_list .year i,
.student_list i {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.upload_marks_detail {
  border: 1px solid #c4c4c4;
  margin: 10px 0;
  border-radius: 5px;
}

.student_Performance_section .loading {
  height: 30vh;
  position: relative;
  background-color: #fff;
}

.student_Performance_section .loading span {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top: 2px solid blue;
  border-left: 2px solid blue;
  border-bottom: 2px solid blue;
  border-right: 2px solid #fff;
  animation: spin 1.6s ease infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.upload_marks {
  padding: 3px 15px;
  border-radius: 10px;
  /* font-size: 1.1rem; */
  border: none;
  color: #fff;
  background-color: #6d8dad;

}

.coures_main {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.coures_input {
  width: 70%;
}

.Heading_Div {
  display: grid;
  place-items: center;
}

.marks_label {
  display: grid;
  place-items: center;
}

.marks_label h5 {
  padding: 5px;
}

/*------css for pagination----------*/
.pagination_outer_wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.dashbord_main .pagination_outer_wrapper {
  justify-content: flex-start;
}

.pagination_inner_wrapper {
  overflow-x: scroll;
}

.pagination_inner_wrapper::-webkit-scrollbar {
  height: 0;
}

.pagination {
  margin-bottom: 0 !important;
}

.pagination li {
  padding: 0px 8px;
  border: 1px solid rgb(109, 141, 173);
  border-radius: 3px;
  margin: 0 3px;
  color: rgb(109, 141, 173);
  position: relative;
}

.pagination .active {
  background-color: rgb(109, 141, 173);
  color: #fff;
}

.pagination .previous a,
.pagination .next a {
  color: rgb(109, 141, 173) !important;
}

/* .pagination .active::after {
  position: absolute;
  content: "";
  top: -14%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 30%;
  background-color: rgb(109, 141, 173);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
} */

.pagination a {
  text-decoration: none;
}

/*-----css for Back office pdf preview page----*/
/* .react-pdf__Document {
  width: 70%;
}
.react-pdf__Page__canvas {
  width: 100% !important;
} */